.infoContainer {
  background-color: var(--info-box-bg-color) !important;
}

.infoContent {
  display: grid;
  grid-template-columns: minmax(min-content, min-content) 1fr;
  column-gap: 0.5rem;
  padding: 0.5rem !important; /* Override Mui-Card default style with larger bottom padding */
}

.infoTextContainer {
  font-style: italic;
}

.infoTextContainer :first-child {
  margin-top: 0;
}

.infoTextContainer :last-child {
  margin-bottom: 0;
}
