.feedbackSubmitButtonContainer {
  margin: 1rem auto;
  text-align: center;
}

.commentFieldContainer {
  margin: 1rem 0;
}

.dataRetentionConsentCheckBoxContainer {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: var(--default-border-radius);
  border-width: 1px;
  padding: 1rem;
  margin: 1rem 0;
}

.commentFieldContainer {
  margin: 1rem 0;
}
