:root {
  --chart-item-color: #6FC0E3;
  --chart-sector-1-color: #FFF4E9;
  --chart-sector-2-color: #E6F7F6;
  --chart-sector-3-color: #FFE9E9;
  --chart-sector-4-color: #F1FBFF;
  /* Choose min- and maxwidth so that chart font and normal content font sizes do not differ too much */
  --app-min-width: 20rem;
  --app-max-width: 40rem;
  --header-bg-color: #fafafa;
  --header-fg-color: black;
  --content-bg-color: #fafafa;
  /* --content-bg-color: #fdfdfd; */
  --line-color: #d6d6d6;
  --idea-champ-blue: #6FC0E3;
  --idea-champ-blue-darker: #61B0D3;
  --box-shadow-size: 0.5rem;
  --box-shadow-color: #d0d0d0;
  /* --box-shadow-color: red; */
  --default-border-radius: 4px;
  --info-box-bg-color: #cfe9f5;
}

/* As default font use Roboto as is recommended by Material-UI */
body { font-family: "Roboto", sans-serif }
