header {
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid var(--line-color);
  box-shadow: 0 0 var(--box-shadow-size) 0 var(--box-shadow-color);
  align-items: center;
  padding: 0 0.5rem;
  z-index: 1000;
}

header img { 
  height: 2.4rem;
  padding-top: 0.2rem;
}
