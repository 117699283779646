.multipleChoiceQuestionContainer {
  margin: 1rem 0;
  border-style: solid;
  /* border-color: #6BA6BF; */
  /* border-color: #f50057; */
  border-color: rgba(0, 0, 0, 0.23);
  /* border-radius: 1rem; */
  /* border-radius: 4px; */
  border-radius: var(--default-border-radius);
  border-width: 1px;
  padding: 1rem;
}

.multipleChoiceQuestionContainer .questionText {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  font-weight: bold;
}
