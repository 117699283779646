.ratingContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  text-align: center;
}

.firstRatingBox {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
}

.secondRatingBox {
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
}

/* .firstRatingBox, .secondRatingBox { */
  /* background-color: #f2f2f4; */
  /* box-shadow: 0 0 2px 1px var(--line-color); */
  /* box-shadow: 0 0 3px 1px #e0e0e0 inset; */
  /* border: 1px solid var(--line-color); */
/* } */

.firstRatingTitle {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.firstRatingValue {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.secondRatingTitle {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.secondRatingValue {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}

.firstRatingTitle, .secondRatingTitle {
  /* padding: 0; */
  font-size: 0.8rem;
  /* font-weight: 300; */
  /* color: #808080; */
  /* text-decoration: underline; */
}

.firstRatingValue, .secondRatingValue {
  display: block;
  margin: 0.25rem auto;
  padding: 0;
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  border-radius: 100%;
  /* background-color: white; */
  /* background-color: var(--content-bg-color); */
  color: var(--idea-champ-blue);
  border: 1px solid var(--line-color);
  box-shadow: 0 0 var(--box-shadow-size) 0 var(--box-shadow-color);
}
