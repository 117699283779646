:root {
  --header-height: 3rem;
  --navbar-height: 3rem;
}

header {
  height: var(--header-height);
}

.contentContainer {
  height: calc(100vh - var(--header-height) - var(--navbar-height));
}

.progress-indication {
  color: var(--idea-champ-blue-darker);
} /* TODO This is evaluation-specific and should be moved to a more specific css file */

.navBar {
  height: var(--navbar-height);
}

/* This seems to elevate the blocks, so that their box shadows do not get drawn under content elements */
header, .navBar { position: relative }
