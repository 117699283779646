.contentContainer {
  padding: 0.5rem 1rem;
}
 
.contentContainer > :first-child {
  margin-top: 0;
}

h2 {
  margin: 0.5rem 0;
  padding: 0 0 0.2rem 0;
}

h3 {
  margin: 1rem 0 0.5rem;
}

p {
  margin: 1rem 0;
}

.info {
  margin: 0.5rem 0;
}

.questionContainer {
  margin: 1rem 0;
}
